@import "../../styles/common";

.overviewContainer {
  width: 100%;
  display: block;
  .overviewSection {
    width: 558px;
    height: fit-content;
    position: relative;
    margin: auto;
    margin-top: 24px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(36, 36, 36, 0.10454);
    padding: 24px;
    .overviewTitleSection {
      .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: $secondary-6;
      }
    }
    .overviewText {
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;

      ul {
        padding-inline-start: 0;
        margin: 0;

        li {
          margin: 16px 16px;
          &::marker {
            color: $gray-5;
          }
        }
      }
    }
  }
  .buttonSection {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(24px - 19px / 2);
    .buttonContent {
      width: 558px;
      .launchButton {
        float: right;
        width: 93px;
        height: 37px;
      }
      .backButton {
        float: left;
        width: 93px;
        height: 37px;
      }
    }
  }
}
