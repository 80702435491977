@import "../../styles/common";

.productsContainer {
  width: 100%;
  display: block;
  .productSection {
    width: fit-content;
    // height: 350px;
    position: relative;
    margin: auto;
    margin-top: 100px;
    background: transparent;
    display: grid;
    grid-template-columns: auto auto auto auto;
    .productLabel {
      font-weight: 700;
      font-size: 20px;
      width: 100%;
      display: flex;
      align-items: end;
      justify-content: center;
      height: 40%;
    }
    .productSubtitle {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      text-align: center;
    }
    .activeProductTile {
      background: #ffffff;
      margin: 16px;
      width: 240px;
      height: 290px;
      box-shadow: 0px 2px 10px rgba(36, 36, 36, 0.10454);
      border-radius: 4px;
      position: relative;
      .buttonSection {
        width: 100%;
        text-align: center;
        display: block !important;
        position: absolute;
        bottom: 16px;
        .selectButton {
          width: 93px;
          margin-bottom: 6px;
        }
        .settingIcon {
          margin-right: -4px;
          color: $primary-color;
        }
      }
    }
    .inactiveProductTile {
      background: #fafafa !important;
      .buttonSection {
        .settingIcon {
          color: rgba(0, 0, 0, 0.25) !important;
        }
      }
    }
  }
}
