@import "styles/common";

.headerContainer {
  height: 48px;
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 30px;
  z-index: 10;
  box-shadow: 0px 4px 8px rgba(36, 36, 36, 0.10454);
  @include for-both-phone-and-tablet {
    height: $header-xs-height;
    padding: 15px 24px;
    .sidebarToggleButton {
      border: none;
      color: $primary-color;
      outline: none;
    }
  }
  .linkSection {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    img {
      height: 20px;
      width: auto;
    }
    @include for-phone-only {
      flex-direction: column;
    }
  }
  .clientName {
    font-weight: 700;
    line-height: 19px;
    margin-left: 32px;
  }

  .spacer {
    flex: 1;
  }
  .userGuide {
    margin-right: 20px;
    line-height: 19px;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
  }
  .userDetailsSection {
    display: flex;
    flex-direction: row;
    .dropdownContainer {
      display: flex;
      flex-direction: row;
      margin-bottom: 0;
      padding-right: 4px;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      .username {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        align-items: flex-end;
        padding-right: 4px;
        font-weight: 700;
        button {
          padding: 0 0 0 0;
          height: 20px;
          text-transform: capitalize;
        }
        .roleText {
          font-size: 12px;
          line-height: 16.37px;
          text-transform: capitalize;
        }
      }
      .dropdownIcon {
        font-size: 14px;
        padding-top: 3px;
        color: $primary-color;
      }
    }
  }
  .pageTitleSecttion {
    margin-right: auto;
    width: 100%;
    text-align: center;
    .pageTitle {
      margin-left: -32px;
      margin-bottom: 0;
      color: $primary-color;
    }
  }
}

.headerDropdownButton {
  color: $text-color;
  padding-left: 5px;
  padding-right: 20px;
  width: 100%;
  text-align: left;
  // font-weight: 400;
}

.footerContainer {
  background-color: $white;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px;
  .linkSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .footerLink {
      margin-right: 10px;
    }
  }
  .copyrightSection {
    display: flex;
    flex-direction: row;
    height: 100%;
    .copyrightText {
      margin-right: 20px;
    }
    img {
      height: 20px;
      width: auto;
    }
  }
}
