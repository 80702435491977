@import "styles/common";

.float-label {
  position: relative;
  margin-bottom: 10px;
  input {
    padding-top: 9px !important;
  }
  .label {
    font-size: 14px;
    color: $text-color-secondary;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 11px;
    transition: 0.2s ease all;
    z-index: 999 !important;
    &.label-float {
      top: 2px;
      font-size: 10px;
      // color: $primary-5;
      z-index: 999 !important;
    }
  }
}
