@import "styles/common";

.productSettingContainer {
  width: 600px;
  display: block;
  .title {
    position: relative;
    margin: auto;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: $secondary-6;
    height: 27px;
    width: 100%;
    margin-top: 30px;
  }
  .productSettingContent {
    position: relative;
    margin: auto;
    width: 100%;
    // height: 447px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(36, 36, 36, 0.10454);
    margin-top: 15px;
    padding: 30px;
    display: block;
    .active {
      color: #262626;
      // line-height: 19px;
    }
    .inactive {
      color: $text-color-secondary;
      // line-height: 19px;
    }
    .inputBox {
      width: 66px;
      height: 40px;
      min-width: 66px;
      border: 1px solid #f0f0f0;
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      padding: 0;
    }
    .percentSymbol {
      margin: 0px 0px 0px 10px;
      font-weight: 700;
    }
    .headings {
      height: 30px;
      padding: 0 20px;
    }
    .centerAlignContent {
      margin-top: 10px;
      display: flex;
      align-items: center;
      position: relative;
    }
    .updateButton {
      // width: 100px;
      height: 36px;
      // margin-top: 14px;
    }
    .fileNameText {
      color: #90a7b9;
      margin-left: 10px;
      width: 276px;
      margin-bottom: 0 !important;
    }
    .separator {
      height: 1px;
      background-color: $secondary-6;
      margin: 16px 0;
    }
    .totalSection {
      height: 30px;
      .errorText {
        color: $error-1;
      }
    }
    .errorMessageSection {
      color: $error-1;
      height: 25px;
      line-height: 19px;
    }
  }
  .buttonSection {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(24px - 19px / 2);
    .buttonContent {
      width: 100%;
      .button {
        width: 93px;
        height: 37px;
      }
    }
  }
}
