@import "styles/common";

.centerAlignContent {
  display: flex;
  align-items: center;

  .iconSection {
    width: 8%;
    text-align: end;
    margin-bottom: 12px;
  }
}

.questionCircleIcon {
  height: 100%;
  font-size: 18px;
  color: #000000;
}

.dashboardContainer {
  width: 100%;
  display: block;
  // padding: 0 94px;
  padding: 0 70px;
  max-width: 1400px;

  .stateSection {
    margin-top: 20px;

    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      color: $secondary-6;
      margin-right: 16px;
    }

    .stateListContent {
      width: 100%;
      // height: 194px;
      padding: 20px;
      background: #ffffff;
      margin-top: 20px;
      box-shadow: 0px 2px 10px rgba(36, 36, 36, 0.10454);
      border-radius: 4px;

      .radioButtons {
        .radioButtonText {
          color: $text-color-secondary;
          line-height: 19px;
        }

        .showStateButton {
          margin: 0px 13px;
          color: $primary-color;
          font-weight: 400;
        }
      }

      .stateListSection {
        .chooseState {
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          color: $secondary-6;
        }

        .selectAll {
          margin-left: 16px;
          padding: 0;
        }

        .separator {
          width: 100%;
          height: 1px;
          background: $normal-color;
        }

        .stateSectionFooter {
          height: 34px;
          display: flex;
          position: relative;

          .stateDisplayExplanation {
            font-size: 12px;
            line-height: 16px;
            color: $gray-7;
            width: 553px;
          }

          .selectedStateCount {
            font-weight: 700;
            font-size: 16px;
            line-height: 37px;
            margin-right: 20px;
          }

          .saveState {
            float: right;
            color: $primary-color;
            border-color: $primary-color;
            width: 87px;
            height: 37px;

            &:disabled {
              color: rgba(0, 0, 0, 0.25);
              background: #f5f5f5;
              border-color: #d9d9d9;
              text-shadow: none;
              box-shadow: none;
            }
          }
        }

        .stateList {
          .selectAll {
            margin-left: 6px;
            padding: 0;
            font-size: 12px !important;
            line-height: 16px !important;
          }

          // .ant-checkbox-group-item {
          //     display: block;
          //     // margin-right: 0;
          // }
          .checkBoxText {
            color: $text-color-secondary;
            line-height: 19px;
          }
        }
      }
    }

    .stateInput {
      width: 45px;
      height: 25px;
      min-width: 30px;
      border: 1px solid #f0f0f0;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      padding: 0;
    }
  }

  .thresholdSection {
    margin-top: 20px;
    width: 100%;

    .thresholdTitle {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      color: $secondary-6;
    }

    .thresholdContent {
      margin-top: 20px;
      display: flow-root;
      width: 100%;

      .riskThresholdSection {
        float: left;
        padding: 20px;
        // width: 567px;
        width: 49%;
        // min-height: 245px;
        background: #ffffff;
        box-shadow: 0px 2px 10px rgba(36, 36, 36, 0.10454);
        border-radius: 4px;

        .radioButtonText {
          color: $text-color-secondary;
          line-height: 19px;
        }

        .riskThresholdTitle {
          font-weight: 700;
        }

        .riskThresholdContent {
          margin-top: 13px;
          display: flex;
          align-items: center;

          .riskThresholdInput {
            width: 66px;
            height: 40px;
            min-width: 66px;
            border: 1px solid #f0f0f0;
            border-radius: 4px;
            font-weight: 700;
            font-size: 16px;
            text-align: center;
            padding: 0;
          }

          .riskThresholdErrorInput {
            width: 66px;
            height: 40px;
            min-width: 66px;
            border: 1px solid $error-1;
            border-radius: 4px;
            color: $error-1;
            font-weight: 700;
            font-size: 16px;
            text-align: center;
            padding: 0;
          }

          .riskThresholdPercentSymbol {
            margin: 0px 20px 0px 10px;
            font-weight: 700;
          }

          .riskThresholdText {
            color: $secondary-6;
            line-height: 19px;
            display: grid;
            // height: 57px;
          }
        }

        .readMoreButton {
          width: fit-content;
        }

        .exclusionCollapseSection {
          // width: 246px;
          width: fit-content;

          .collapseIcon {
            font-size: 18px;
            // color: $primary-color;
            color: #d9d9d9 !important;
            // padding: 10px 0;
            vertical-align: sub;
          }
        }

        .exclusionCheckBoxSection {
          // margin-left: 35px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .active {
            color: #262626;
            line-height: 19px;
          }

          .inactive {
            color: $text-color-secondary;
            line-height: 19px;
          }
        }

        .riskThresholdErrorSection {
          height: 19px;
          margin: 6px 0 10px 0;

          .riskThresholdError {
            color: $error-1;
            margin-top: 7px;
            line-height: 19px;
          }
        }
      }
    }
  }

  .buttonSection {
    margin-top: 20px;
    width: 100%;
    display: flow-root;

    .resetRunSection {
      float: left;
      width: 49%;

      .resetButton {
        padding: 0;
      }

      .runButton {
        height: 37px;
      }
    }

    .downloadSection {
      float: right;
      text-align: right;

      .downloadIndicationText {
        margin-right: 12px;
        color: $secondary-6;
      }

      .downloadIndicationIcon {
        height: 24px;
        width: 24px;
        color: $success-color;
        // .anticon-check-circle {
        //     color: $success-color;
        // }
      }

      .downloadButton {
        height: 37px;
        color: #00a8e1;
        border-color: #00a8e1;
        margin-left: 16px;

        &:disabled {
          color: rgba(0, 0, 0, 0.25);
          background: #f5f5f5;
          border-color: #d9d9d9;
          text-shadow: none;
          box-shadow: none;
        }
      }
    }
  }
}
