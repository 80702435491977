$blue-base: #1890ff;
$blue-1: #e6f7ff;
$blue-2: #bae7ff;
$blue-3: #91d5ff;
$blue-4: #69c0ff;
$blue-5: #40a9ff;
$blue-6: #1890ff;
$blue-7: #096dd9;
$blue-8: #0050b3;
$blue-9: #003a8c;
$blue-10: #002766;
$purple-base: #722ed1;
$purple-1: #f9f0ff;
$purple-2: #efdbff;
$purple-3: #d3adf7;
$purple-4: #b37feb;
$purple-5: #9254de;
$purple-6: #722ed1;
$purple-7: #531dab;
$purple-8: #391085;
$purple-9: #22075e;
$purple-10: #120338;
$cyan-base: #13c2c2;
$cyan-1: #e6fffb;
$cyan-2: #b5f5ec;
$cyan-3: #87e8de;
$cyan-4: #5cdbd3;
$cyan-5: #36cfc9;
$cyan-6: #13c2c2;
$cyan-7: #08979c;
$cyan-8: #006d75;
$cyan-9: #00474f;
$cyan-10: #002329;
$green-base: #52c41a;
$green-1: #f6ffed;
$green-2: #d9f7be;
$green-3: #b7eb8f;
$green-4: #95de64;
$green-5: #73d13d;
$green-6: #52c41a;
$green-7: #389e0d;
$green-8: #237804;
$green-9: #135200;
$green-10: #092b00;
$magenta-base: #eb2f96;
$magenta-1: #fff0f6;
$magenta-2: #ffd6e7;
$magenta-3: #ffadd2;
$magenta-4: #ff85c0;
$magenta-5: #f759ab;
$magenta-6: #eb2f96;
$magenta-7: #c41d7f;
$magenta-8: #9e1068;
$magenta-9: #780650;
$magenta-10: #520339;
$pink-base: #eb2f96;
$pink-1: #fff0f6;
$pink-2: #ffd6e7;
$pink-3: #ffadd2;
$pink-4: #ff85c0;
$pink-5: #f759ab;
$pink-6: #eb2f96;
$pink-7: #c41d7f;
$pink-8: #9e1068;
$pink-9: #780650;
$pink-10: #520339;
$red-base: #f5222d;
$red-1: #fff1f0;
$red-2: #ffccc7;
$red-3: #ffa39e;
$red-4: #ff7875;
$red-5: #ff4d4f;
$red-6: #f5222d;
$red-7: #cf1322;
$red-8: #a8071a;
$red-9: #820014;
$red-10: #5c0011;
$orange-base: #fa8c16;
$orange-1: #fff7e6;
$orange-2: #ffe7ba;
$orange-3: #ffd591;
$orange-4: #ffc069;
$orange-5: #ffa940;
$orange-6: #fa8c16;
$orange-7: #d46b08;
$orange-8: #ad4e00;
$orange-9: #873800;
$orange-10: #612500;
$yellow-base: #fadb14;
$yellow-1: #feffe6;
$yellow-2: #ffffb8;
$yellow-3: #fffb8f;
$yellow-4: #fff566;
$yellow-5: #ffec3d;
$yellow-6: #fadb14;
$yellow-7: #d4b106;
$yellow-8: #ad8b00;
$yellow-9: #876800;
$yellow-10: #614700;
$volcano-base: #fa541c;
$volcano-1: #fff2e8;
$volcano-2: #ffd8bf;
$volcano-3: #ffbb96;
$volcano-4: #ff9c6e;
$volcano-5: #ff7a45;
$volcano-6: #fa541c;
$volcano-7: #d4380d;
$volcano-8: #ad2102;
$volcano-9: #871400;
$volcano-10: #610b00;
$geekblue-base: #2f54eb;
$geekblue-1: #f0f5ff;
$geekblue-2: #d6e4ff;
$geekblue-3: #adc6ff;
$geekblue-4: #85a5ff;
$geekblue-5: #597ef7;
$geekblue-6: #2f54eb;
$geekblue-7: #1d39c4;
$geekblue-8: #10239e;
$geekblue-9: #061178;
$geekblue-10: #030852;
$lime-base: #a0d911;
$lime-1: #fcffe6;
$lime-2: #f4ffb8;
$lime-3: #eaff8f;
$lime-4: #d3f261;
$lime-5: #bae637;
$lime-6: #a0d911;
$lime-7: #7cb305;
$lime-8: #5b8c00;
$lime-9: #3f6600;
$lime-10: #254000;
$gold-base: #faad14;
$gold-1: #fffbe6;
$gold-2: #fff1b8;
$gold-3: #ffe58f;
$gold-4: #ffd666;
$gold-5: #ffc53d;
$gold-6: #faad14;
$gold-7: #d48806;
$gold-8: #ad6800;
$gold-9: #874d00;
$gold-10: #613400;
$preset-colors: pink, magenta, red, volcano, orange, yellow, gold, cyan, lime, green, blue, geekblue, purple;
$theme: default;
$ant-prefix: ant;
$html-selector: html;
$primary-color: #00a8e1;
$primary-color-hover: #26c2ed;
$primary-color-active: #0085ba;
$primary-color-outline: rgba(0, 168, 225, 0.2);
$processing-color: #1890ff;
$info-color: #00a8e1;
$info-color-deprecated-bg: #e6fdff;
$info-color-deprecated-border: #7aebff;
$success-color: #42cf88;
$success-color-hover: #69db9e;
$success-color-active: #2da86f;
$success-color-outline: rgba(66, 207, 136, 0.2);
$success-color-deprecated-bg: #f0fff5;
$success-color-deprecated-border: #c4f5d7;
$warning-color: #faad14;
$warning-color-hover: #ffc53d;
$warning-color-active: #d48806;
$warning-color-outline: rgba(250, 173, 20, 0.2);
$warning-color-deprecated-bg: #fffbe6;
$warning-color-deprecated-border: #ffe58f;
$error-color: #ff4d4f;
$error-color-hover: #ff7875;
$error-color-active: #d9363e;
$error-color-outline: rgba(255, 77, 79, 0.2);
$error-color-deprecated-bg: #fff2f0;
$error-color-deprecated-border: #ffccc7;
$highlight-color: #ff4d4f;
$normal-color: #d9d9d9;
$white: #fff;
$black: #000;
$primary-1: #e6fdff;
$primary-2: #a3f4ff;
$primary-3: #7aebff;
$primary-4: #50dbfa;
$primary-5: #26c2ed;
$primary-6: #00a8e1;
$primary-7: #0085ba;
$primary-8: #006594;
$primary-9: #00476e;
$primary-10: #002c47;
$body-background: #f1f5f8;
$component-background: #fff;
$popover-background: #fff;
$popover-customize-border-color: #f0f0f0;
$font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$code-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
$text-color: #262626;
$text-color-secondary: #8c8c8c;
$text-color-inverse: #fff;
$icon-color: inherit;
$icon-color-hover: rgba(0, 0, 0, 0.75);
$heading-color: rgba(0, 0, 0, 0.85);
$text-color-dark: rgba(255, 255, 255, 0.85);
$text-color-secondary-dark: rgba(255, 255, 255, 0.65);
$text-selection-bg: #00a8e1;
$font-variant-base: tabular-nums;
$font-feature-settings-base: 'tnum';
$font-size-base: 14px;
$font-size-lg: 16px;
$font-size-sm: 12px;
$heading-1-size: 38px;
$heading-2-size: 30px;
$heading-3-size: 24px;
$heading-4-size: 20px;
$heading-5-size: 16px;
$line-height-base: 1.5715;
$border-radius-base: 4px;
$border-radius-sm: 2px;
$control-border-radius: 4px;
$arrow-border-radius: 2px;
$padding-lg: 24px;
$padding-md: 16px;
$padding-sm: 12px;
$padding-xs: 8px;
$padding-xss: 4px;
$control-padding-horizontal: 12px;
$control-padding-horizontal-sm: 8px;
$margin-lg: 24px;
$margin-md: 16px;
$margin-sm: 12px;
$margin-xs: 8px;
$margin-xss: 4px;
$height-base: 32px;
$height-lg: 40px;
$height-sm: 24px;
$item-active-bg: #e6fdff;
$item-hover-bg: #f5f5f5;
$iconfont-css-prefix: anticon;
$link-color: #00a8e1;
$link-hover-color: #26c2ed;
$link-active-color: #0085ba;
$link-decoration: none;
$link-hover-decoration: none;
$link-focus-decoration: none;
$link-focus-outline: 0;
$ease-base-out: cubic-bezier(0.7, 0.3, 0.1, 1);
$ease-base-in: cubic-bezier(0.9, 0, 0.3, 0.7);
$ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-out-back: cubic-bezier(0.12, 0.4, 0.29, 1.46);
$ease-in-back: cubic-bezier(0.71, -0.46, 0.88, 0.6);
$ease-in-out-back: cubic-bezier(0.71, -0.46, 0.29, 1.46);
$ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.34);
$ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$border-color-base: #d9d9d9;
$border-color-split: #f0f0f0;
$border-color-inverse: #fff;
$border-width-base: 1px;
$border-style-base: solid;
$outline-blur-size: 0;
$outline-width: 2px;
$outline-color: #00a8e1;
$outline-fade: 20%;
$background-color-light: #fafafa;
$background-color-base: #f5f5f5;
$disabled-color: rgba(0, 0, 0, 0.25);
$disabled-bg: #f5f5f5;
$disabled-active-bg: #e6e6e6;
$disabled-color-dark: rgba(255, 255, 255, 0.35);
$shadow-color: rgba(0, 0, 0, 0.15);
$shadow-color-inverse: #fff;
$box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
$shadow-1-up: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
$shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
$shadow-1-left: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
$shadow-1-right: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
$shadow-2: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
$btn-font-weight: 700;
$btn-border-radius-base: 4px;
$btn-border-radius-sm: 4px;
$btn-border-width: 1px;
$btn-border-style: solid;
$btn-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
$btn-primary-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
$btn-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
$btn-primary-color: #fff;
$btn-primary-bg: #00a8e1;
$btn-default-color: #262626;
$btn-default-bg: #fff;
$btn-default-border: #d9d9d9;
$btn-danger-color: #fff;
$btn-danger-bg: #ff4d4f;
$btn-danger-border: #ff4d4f;
$btn-disable-color: rgba(0, 0, 0, 0.25);
$btn-disable-bg: #f5f5f5;
$btn-disable-border: #d9d9d9;
$btn-default-ghost-color: #fff;
$btn-default-ghost-bg: transparent;
$btn-default-ghost-border: #fff;
$btn-font-size-lg: 16px;
$btn-font-size-sm: 14px;
$btn-padding-horizontal-base: 15px;
$btn-padding-horizontal-lg: 15px;
$btn-padding-horizontal-sm: 7px;
$btn-height-base: 32px;
$btn-height-lg: 40px;
$btn-height-sm: 24px;
$btn-line-height: 1.5715;
$btn-circle-size: 32px;
$btn-circle-size-lg: 40px;
$btn-circle-size-sm: 24px;
$btn-square-size: 32px;
$btn-square-size-lg: 40px;
$btn-square-size-sm: 24px;
$btn-square-only-icon-size: 16px;
$btn-square-only-icon-size-sm: 14px;
$btn-square-only-icon-size-lg: 18px;
$btn-group-border: #26c2ed;
$btn-link-hover-bg: transparent;
$btn-text-hover-bg: rgba(0, 0, 0, 0.018);
$checkbox-size: 16px;
$checkbox-color: #00a8e1;
$checkbox-check-color: #fff;
$checkbox-check-bg: #fff;
$checkbox-border-width: 1px;
$checkbox-border-radius: 2px;
$checkbox-group-item-margin-right: 8px;
$descriptions-bg: #fafafa;
$descriptions-title-margin-bottom: 20px;
$descriptions-default-padding: 16px 24px;
$descriptions-middle-padding: 12px 24px;
$descriptions-small-padding: 8px 16px;
$descriptions-item-padding-bottom: 16px;
$descriptions-item-trailing-colon: true;
$descriptions-item-label-colon-margin-right: 8px;
$descriptions-item-label-colon-margin-left: 2px;
$descriptions-extra-color: #262626;
$divider-text-padding: 1em;
$divider-orientation-margin: 5%;
$divider-color: rgba(0, 0, 0, 0.06);
$divider-vertical-gutter: 8px;
$dropdown-selected-color: #00a8e1;
$dropdown-menu-submenu-disabled-bg: #fff;
$dropdown-selected-bg: #e6fdff;
$empty-font-size: 14px;
$radio-size: 16px;
$radio-top: 0.2em;
$radio-border-width: 1px;
$radio-dot-size: 8px;
$radio-dot-color: #00a8e1;
$radio-dot-disabled-color: rgba(0, 0, 0, 0.2);
$radio-solid-checked-color: #fff;
$radio-button-bg: #fff;
$radio-button-checked-bg: #fff;
$radio-button-color: #262626;
$radio-button-hover-color: #26c2ed;
$radio-button-active-color: #0085ba;
$radio-button-padding-horizontal: 15px;
$radio-disabled-button-checked-bg: #e6e6e6;
$radio-disabled-button-checked-color: rgba(0, 0, 0, 0.25);
$radio-wrapper-margin-right: 8px;
$screen-xs: 480px;
$screen-xs-min: 480px;
$screen-sm: 576px;
$screen-sm-min: 576px;
$screen-md: 768px;
$screen-md-min: 768px;
$screen-lg: 992px;
$screen-lg-min: 992px;
$screen-xl: 1200px;
$screen-xl-min: 1200px;
$screen-xxl: 1600px;
$screen-xxl-min: 1600px;
$screen-xs-max: 575px;
$screen-sm-max: 767px;
$screen-md-max: 991px;
$screen-lg-max: 1199px;
$screen-xl-max: 1599px;
$grid-columns: 24;
$layout-body-background: #f0f2f5;
$layout-header-background: #001529;
$layout-header-height: 64px;
$layout-header-padding: 0 50px;
$layout-header-color: #262626;
$layout-footer-padding: 24px 50px;
$layout-footer-background: #f0f2f5;
$layout-sider-background: #001529;
$layout-trigger-height: 48px;
$layout-trigger-background: #002140;
$layout-trigger-color: #fff;
$layout-zero-trigger-width: 36px;
$layout-zero-trigger-height: 42px;
$layout-sider-background-light: #fff;
$layout-trigger-background-light: #fff;
$layout-trigger-color-light: #262626;
$zindex-badge: auto;
$zindex-table-fixed: 2;
$zindex-affix: 10;
$zindex-back-top: 10;
$zindex-picker-panel: 10;
$zindex-popup-close: 10;
$zindex-modal: 1000;
$zindex-modal-mask: 1000;
$zindex-message: 1010;
$zindex-notification: 1010;
$zindex-popover: 1030;
$zindex-dropdown: 1050;
$zindex-picker: 1050;
$zindex-popoconfirm: 1060;
$zindex-tooltip: 1070;
$zindex-image: 1080;
$animation-duration-slow: 0.3s;
$animation-duration-base: 0.2s;
$animation-duration-fast: 0.1s;
$collapse-panel-border-radius: 4px;
$dropdown-menu-bg: #fff;
$dropdown-vertical-padding: 5px;
$dropdown-edge-child-vertical-padding: 4px;
$dropdown-font-size: 14px;
$dropdown-line-height: 22px;
$label-required-color: #ff4d4f;
$label-color: rgba(0, 0, 0, 0.85);
$form-warning-input-bg: #fff;
$form-item-margin-bottom: 24px;
$form-item-trailing-colon: true;
$form-vertical-label-padding: 0 0 8px;
$form-vertical-label-margin: 0;
$form-item-label-font-size: 14px;
$form-item-label-height: 32px;
$form-item-label-colon-margin-right: 8px;
$form-item-label-colon-margin-left: 2px;
$form-error-input-bg: #fff;
$input-height-base: 32px;
$input-height-lg: 40px;
$input-height-sm: 24px;
$input-padding-horizontal: 11px;
$input-padding-horizontal-base: 11px;
$input-padding-horizontal-sm: 7px;
$input-padding-horizontal-lg: 11px;
$input-padding-vertical-base: 4px;
$input-padding-vertical-sm: 0px;
$input-padding-vertical-lg: 6.5px;
$input-placeholder-color: #8c8c8c;
$input-color: #262626;
$input-icon-color: #262626;
$input-border-color: #d9d9d9;
$input-bg: #fff;
$input-number-hover-border-color: #26c2ed;
$input-number-handler-active-bg: #f4f4f4;
$input-number-handler-hover-bg: #26c2ed;
$input-number-handler-bg: #fff;
$input-number-handler-border-color: #d9d9d9;
$input-addon-bg: #fafafa;
$input-hover-border-color: #26c2ed;
$input-disabled-bg: #f5f5f5;
$input-outline-offset: 0 0;
$input-icon-hover-color: rgba(0, 0, 0, 0.85);
$input-disabled-color: rgba(0, 0, 0, 0.25);
$mentions-dropdown-bg: #fff;
$mentions-dropdown-menu-item-hover-bg: #fff;
$select-border-color: #d9d9d9;
$select-item-selected-color: #262626;
$select-item-selected-font-weight: 600;
$select-dropdown-bg: #fff;
$select-item-selected-bg: #e6fdff;
$select-item-active-bg: #f5f5f5;
$select-dropdown-vertical-padding: 5px;
$select-dropdown-font-size: 14px;
$select-dropdown-line-height: 22px;
$select-dropdown-height: 32px;
$select-background: #fff;
$select-clear-background: #fff;
$select-selection-item-bg: #f5f5f5;
$select-selection-item-border-color: #f0f0f0;
$select-single-item-height-lg: 40px;
$select-multiple-item-height: 24px;
$select-multiple-item-height-lg: 32px;
$select-multiple-item-spacing-half: 2px;
$select-multiple-disabled-background: #f5f5f5;
$select-multiple-item-disabled-color: #bfbfbf;
$select-multiple-item-disabled-border-color: #d9d9d9;
$cascader-bg: #fff;
$cascader-item-selected-bg: #e6fdff;
$cascader-menu-bg: #fff;
$cascader-menu-border-color-split: #f0f0f0;
$cascader-dropdown-vertical-padding: 5px;
$cascader-dropdown-edge-child-vertical-padding: 4px;
$cascader-dropdown-font-size: 14px;
$cascader-dropdown-line-height: 22px;
$anchor-bg: transparent;
$anchor-border-color: #f0f0f0;
$anchor-link-top: 4px;
$anchor-link-left: 16px;
$anchor-link-padding: 4px 0 4px 16px;
$tooltip-max-width: 250px;
$tooltip-color: #fff;
$tooltip-bg: rgba(0, 0, 0, 0.75);
$tooltip-arrow-width: 11.3137085px;
$tooltip-distance: 14.3137085px;
$tooltip-arrow-color: rgba(0, 0, 0, 0.75);
$tooltip-border-radius: 4px;
$popover-bg: #fff;
$popover-color: #262626;
$popover-min-width: 177px;
$popover-min-height: 32px;
$popover-arrow-width: 11.3137085px;
$popover-arrow-color: #fff;
$popover-arrow-outer-color: #fff;
$popover-distance: 15.3137085px;
$popover-padding-horizontal: 16px;
$modal-header-padding-vertical: 16px;
$modal-header-padding-horizontal: 24px;
$modal-body-padding: 24px;
$modal-header-bg: #fff;
$modal-header-padding: 16px 24px;
$modal-header-border-width: 1px;
$modal-header-border-style: solid;
$modal-header-title-line-height: 22px;
$modal-header-title-font-size: 16px;
$modal-header-border-color-split: #f0f0f0;
$modal-header-close-size: 54px;
$modal-content-bg: #fff;
$modal-heading-color: rgba(0, 0, 0, 0.85);
$modal-close-color: #8c8c8c;
$modal-footer-bg: transparent;
$modal-footer-border-color-split: #f0f0f0;
$modal-footer-border-style: solid;
$modal-footer-padding-vertical: 10px;
$modal-footer-padding-horizontal: 16px;
$modal-footer-border-width: 1px;
$modal-mask-bg: rgba(0, 0, 0, 0.45);
$modal-confirm-body-padding: 32px 32px 24px;
$modal-confirm-title-font-size: 16px;
$modal-border-radius: 4px;
$progress-default-color: #1890ff;
$progress-remaining-color: #f5f5f5;
$progress-info-text-color: #262626;
$progress-radius: 100px;
$progress-steps-item-bg: #f3f3f3;
$progress-text-font-size: 1em;
$progress-text-color: #262626;
$progress-circle-text-font-size: 1em;
$menu-inline-toplevel-item-height: 40px;
$menu-item-height: 40px;
$menu-item-group-height: 1.5715;
$menu-collapsed-width: 80px;
$menu-bg: #fff;
$menu-popup-bg: #fff;
$menu-item-color: #262626;
$menu-inline-submenu-bg: #fafafa;
$menu-highlight-color: #00a8e1;
$menu-highlight-danger-color: #ff4d4f;
$menu-item-active-bg: #e6fdff;
$menu-item-active-danger-bg: #fff1f0;
$menu-item-active-border-width: 3px;
$menu-item-group-title-color: #8c8c8c;
$menu-item-vertical-margin: 4px;
$menu-item-font-size: 14px;
$menu-item-boundary-margin: 8px;
$menu-item-padding-horizontal: 20px;
$menu-item-padding: 0 20px;
$menu-horizontal-line-height: 46px;
$menu-icon-margin-right: 10px;
$menu-icon-size: 14px;
$menu-icon-size-lg: 16px;
$menu-item-group-title-font-size: 14px;
$menu-dark-color: rgba(255, 255, 255, 0.65);
$menu-dark-danger-color: #ff4d4f;
$menu-dark-bg: #001529;
$menu-dark-arrow-color: #fff;
$menu-dark-inline-submenu-bg: #000c17;
$menu-dark-highlight-color: #fff;
$menu-dark-item-active-bg: #00a8e1;
$menu-dark-item-active-danger-bg: #ff4d4f;
$menu-dark-selected-item-icon-color: #fff;
$menu-dark-selected-item-text-color: #fff;
$menu-dark-item-hover-bg: transparent;
$spin-dot-size-sm: 14px;
$spin-dot-size: 20px;
$spin-dot-size-lg: 32px;
$table-bg: #fff;
$table-header-bg: #fafafa;
$table-header-color: rgba(0, 0, 0, 0.85);
$table-header-sort-bg: #f5f5f5;
$table-body-sort-bg: #fafafa;
$table-row-hover-bg: #fafafa;
$table-selected-row-color: inherit;
$table-selected-row-bg: #e6fdff;
$table-body-selected-sort-bg: #e6fdff;
$table-selected-row-hover-bg: #dcfcff;
$table-expanded-row-bg: #fbfbfb;
$table-padding-vertical: 16px;
$table-padding-horizontal: 16px;
$table-padding-vertical-md: 12px;
$table-padding-horizontal-md: 8px;
$table-padding-vertical-sm: 8px;
$table-padding-horizontal-sm: 8px;
$table-border-color: #f0f0f0;
$table-border-radius-base: 4px;
$table-footer-bg: #fafafa;
$table-footer-color: rgba(0, 0, 0, 0.85);
$table-header-bg-sm: #fafafa;
$table-font-size: 14px;
$table-font-size-md: 14px;
$table-font-size-sm: 14px;
$table-header-cell-split-color: rgba(0, 0, 0, 0.06);
$table-header-sort-active-bg: rgba(0, 0, 0, 0.04);
$table-fixed-header-sort-active-bg: #f5f5f5;
$table-header-filter-active-bg: rgba(0, 0, 0, 0.04);
$table-filter-btns-bg: inherit;
$table-filter-dropdown-bg: #fff;
$table-expand-icon-bg: #fff;
$table-selection-column-width: 32px;
$table-sticky-scroll-bar-bg: rgba(0, 0, 0, 0.35);
$table-sticky-scroll-bar-radius: 4px;
$tag-border-radius: 4px;
$tag-default-bg: #f0f0f0;
$tag-default-color: #8c8c8c;
$tag-font-size: 12px;
$tag-line-height: 20px;
$picker-bg: #fff;
$picker-basic-cell-hover-color: #f5f5f5;
$picker-basic-cell-active-with-range-color: #e6fdff;
$picker-basic-cell-hover-with-range-color: #95e4ff;
$picker-basic-cell-disabled-bg: rgba(0, 0, 0, 0.04);
$picker-border-color: #f0f0f0;
$picker-date-hover-range-border-color: #48d1ff;
$picker-date-hover-range-color: #95e4ff;
$picker-time-panel-column-width: 56px;
$picker-time-panel-column-height: 224px;
$picker-time-panel-cell-height: 28px;
$picker-panel-cell-height: 24px;
$picker-panel-cell-width: 36px;
$picker-text-height: 40px;
$picker-panel-without-time-cell-height: 66px;
$calendar-bg: #fff;
$calendar-input-bg: #fff;
$calendar-border-color: #fff;
$calendar-item-active-bg: #e6fdff;
$calendar-column-active-bg: rgba(230, 253, 255, 0.2);
$calendar-full-bg: #fff;
$calendar-full-panel-bg: #fff;
$carousel-dot-width: 16px;
$carousel-dot-height: 3px;
$carousel-dot-active-width: 24px;
$badge-height: 20px;
$badge-height-sm: 14px;
$badge-dot-size: 6px;
$badge-font-size: 12px;
$badge-font-size-sm: 12px;
$badge-font-weight: normal;
$badge-status-size: 6px;
$badge-text-color: #fff;
$badge-color: #ff4d4f;
$rate-star-color: #fadb14;
$rate-star-bg: #f0f0f0;
$rate-star-size: 20px;
$rate-star-hover-scale: scale(1.1);
$card-head-color: rgba(0, 0, 0, 0.85);
$card-head-background: transparent;
$card-head-font-size: 16px;
$card-head-font-size-sm: 14px;
$card-head-padding: 16px;
$card-head-padding-sm: 8px;
$card-head-height: 48px;
$card-head-height-sm: 36px;
$card-inner-head-padding: 12px;
$card-padding-base: 24px;
$card-padding-base-sm: 12px;
$card-actions-background: #fff;
$card-actions-li-margin: 12px 0;
$card-skeleton-bg: #cfd8dc;
$card-background: #fff;
$card-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
$card-radius: 4px;
$card-head-tabs-margin-bottom: -17px;
$card-head-extra-color: #262626;
$comment-bg: inherit;
$comment-padding-base: 16px 0;
$comment-nest-indent: 44px;
$comment-font-size-base: 14px;
$comment-font-size-sm: 12px;
$comment-author-name-color: #8c8c8c;
$comment-author-time-color: #ccc;
$comment-action-color: #8c8c8c;
$comment-action-hover-color: #595959;
$comment-actions-margin-bottom: inherit;
$comment-actions-margin-top: 12px;
$comment-content-detail-p-margin-bottom: inherit;
$tabs-card-head-background: #fafafa;
$tabs-card-height: 40px;
$tabs-card-active-color: #00a8e1;
$tabs-card-horizontal-padding: 8px 16px;
$tabs-card-horizontal-padding-sm: 6px 16px;
$tabs-card-horizontal-padding-lg: 7px 16px 6px;
$tabs-title-font-size: 14px;
$tabs-title-font-size-lg: 16px;
$tabs-title-font-size-sm: 14px;
$tabs-ink-bar-color: #00a8e1;
$tabs-bar-margin: 0 0 16px 0;
$tabs-horizontal-gutter: 32px;
$tabs-horizontal-margin: 0 0 0 32px;
$tabs-horizontal-margin-rtl: 0 0 0 32px;
$tabs-horizontal-padding: 12px 0;
$tabs-horizontal-padding-lg: 16px 0;
$tabs-horizontal-padding-sm: 8px 0;
$tabs-vertical-padding: 8px 24px;
$tabs-vertical-margin: 16px 0 0 0;
$tabs-scrolling-size: 32px;
$tabs-highlight-color: #00a8e1;
$tabs-hover-color: #26c2ed;
$tabs-active-color: #0085ba;
$tabs-card-gutter: 2px;
$tabs-card-tab-active-border-top: 2px solid transparent;
$back-top-color: #fff;
$back-top-bg: #8c8c8c;
$back-top-hover-bg: #262626;
$avatar-size-base: 32px;
$avatar-size-lg: 40px;
$avatar-size-sm: 24px;
$avatar-font-size-base: 18px;
$avatar-font-size-lg: 24px;
$avatar-font-size-sm: 14px;
$avatar-bg: #ccc;
$avatar-color: #fff;
$avatar-border-radius: 4px;
$avatar-group-overlapping: -8px;
$avatar-group-space: 3px;
$avatar-group-border-color: #fff;
$switch-height: 22px;
$switch-sm-height: 16px;
$switch-min-width: 44px;
$switch-sm-min-width: 28px;
$switch-disabled-opacity: 0.4;
$switch-color: #00a8e1;
$switch-bg: #fff;
$switch-shadow-color: rgba(0, 35, 11, 0.2);
$switch-padding: 2px;
$switch-inner-margin-min: 7px;
$switch-inner-margin-max: 25px;
$switch-sm-inner-margin-min: 5px;
$switch-sm-inner-margin-max: 18px;
$pagination-item-bg: #fff;
$pagination-item-size: 32px;
$pagination-item-size-sm: 24px;
$pagination-font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$pagination-font-weight-active: 500;
$pagination-item-bg-active: #fff;
$pagination-item-link-bg: #fff;
$pagination-item-disabled-color-active: rgba(0, 0, 0, 0.25);
$pagination-item-disabled-bg-active: #e6e6e6;
$pagination-item-input-bg: #fff;
$pagination-mini-options-size-changer-top: 0px;
$page-header-padding: 24px;
$page-header-padding-vertical: 16px;
$page-header-padding-breadcrumb: 12px;
$page-header-content-padding-vertical: 12px;
$page-header-back-color: #000;
$page-header-ghost-bg: inherit;
$page-header-heading-title: 20px;
$page-header-heading-sub-title: 14px;
$page-header-tabs-tab-font-size: 16px;
$breadcrumb-base-color: #8c8c8c;
$breadcrumb-last-item-color: #262626;
$breadcrumb-font-size: 14px;
$breadcrumb-icon-font-size: 14px;
$breadcrumb-link-color: #8c8c8c;
$breadcrumb-link-color-hover: #262626;
$breadcrumb-separator-color: #8c8c8c;
$breadcrumb-separator-margin: 0 8px;
$slider-margin: 10px 6px 10px;
$slider-rail-background-color: #f5f5f5;
$slider-rail-background-color-hover: #e1e1e1;
$slider-track-background-color: #7aebff;
$slider-track-background-color-hover: #50dbfa;
$slider-handle-border-width: 2px;
$slider-handle-background-color: #fff;
$slider-handle-color: #7aebff;
$slider-handle-color-hover: #50dbfa;
$slider-handle-color-focus: #33b9e7;
$slider-handle-color-focus-shadow: rgba(0, 168, 225, 0.12);
$slider-handle-color-tooltip-open: #00a8e1;
$slider-handle-size: 14px;
$slider-handle-margin-top: -5px;
$slider-handle-margin-left: -5px;
$slider-handle-shadow: 0;
$slider-dot-border-color: #f0f0f0;
$slider-dot-border-color-active: #80d4f0;
$slider-disabled-color: rgba(0, 0, 0, 0.25);
$slider-disabled-background-color: #fff;
$tree-bg: #fff;
$tree-title-height: 24px;
$tree-child-padding: 18px;
$tree-directory-selected-color: #fff;
$tree-directory-selected-bg: #00a8e1;
$tree-node-hover-bg: #f5f5f5;
$tree-node-selected-bg: #a3f4ff;
$collapse-header-padding: 12px 16px;
$collapse-header-padding-extra: 40px;
$collapse-header-bg: #fafafa;
$collapse-content-padding: 16px;
$collapse-content-bg: #fff;
$collapse-header-arrow-left: 16px;
$skeleton-color: rgba(190, 190, 190, 0.2);
$skeleton-to-color: rgba(129, 129, 129, 0.24);
$skeleton-paragraph-margin-top: 28px;
$skeleton-paragraph-li-margin-top: 16px;
$skeleton-paragraph-li-height: 16px;
$skeleton-title-height: 16px;
$skeleton-title-paragraph-margin-top: 24px;
$transfer-header-height: 40px;
$transfer-item-height: 32px;
$transfer-disabled-bg: #f5f5f5;
$transfer-list-height: 200px;
$transfer-item-hover-bg: #f5f5f5;
$transfer-item-selected-hover-bg: #dcfcff;
$transfer-item-padding-vertical: 6px;
$transfer-list-search-icon-top: 12px;
$message-notice-content-padding: 10px 16px;
$message-notice-content-bg: #fff;
$wave-animation-width: 6px;
$alert-success-border-color: #c4f5d7;
$alert-success-bg-color: #f0fff5;
$alert-success-icon-color: #42cf88;
$alert-info-border-color: #7aebff;
$alert-info-bg-color: #e6fdff;
$alert-info-icon-color: #00a8e1;
$alert-warning-border-color: #ffe58f;
$alert-warning-bg-color: #fffbe6;
$alert-warning-icon-color: #faad14;
$alert-error-border-color: #ffccc7;
$alert-error-bg-color: #fff2f0;
$alert-error-icon-color: #ff4d4f;
$alert-message-color: rgba(0, 0, 0, 0.85);
$alert-text-color: #262626;
$alert-close-color: #8c8c8c;
$alert-close-hover-color: rgba(0, 0, 0, 0.75);
$alert-padding-vertical: 8px;
$alert-padding-horizontal: 15px;
$alert-no-icon-padding-vertical: 8px;
$alert-with-description-no-icon-padding-vertical: 15px;
$alert-with-description-padding-vertical: 15px;
$alert-with-description-padding: 15px 15px 15px 24px;
$alert-icon-top: 12.0005px;
$alert-with-description-icon-size: 24px;
$list-header-background: transparent;
$list-footer-background: transparent;
$list-empty-text-padding: 16px;
$list-item-padding: 12px 0;
$list-item-padding-sm: 8px 16px;
$list-item-padding-lg: 16px 24px;
$list-item-meta-margin-bottom: 16px;
$list-item-meta-avatar-margin-right: 16px;
$list-item-meta-title-margin-bottom: 12px;
$list-customize-card-bg: #fff;
$list-item-meta-description-font-size: 14px;
$statistic-title-font-size: 14px;
$statistic-content-font-size: 24px;
$statistic-unit-font-size: 24px;
$statistic-font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$drawer-header-padding: 16px 24px;
$drawer-body-padding: 24px;
$drawer-bg: #fff;
$drawer-footer-padding-vertical: 10px;
$drawer-footer-padding-horizontal: 16px;
$drawer-header-close-size: 56px;
$drawer-title-font-size: 16px;
$drawer-title-line-height: 22px;
$timeline-width: 2px;
$timeline-color: #f0f0f0;
$timeline-dot-border-width: 2px;
$timeline-dot-color: #00a8e1;
$timeline-dot-bg: #fff;
$timeline-item-padding-bottom: 20px;
$typography-title-font-weight: 700;
$typography-title-margin-top: 1.2em;
$typography-title-margin-bottom: 0.5em;
$upload-actions-color: #8c8c8c;
$process-tail-color: #f0f0f0;
$steps-nav-arrow-color: rgba(0, 0, 0, 0.25);
$steps-background: #fff;
$steps-icon-size: 32px;
$steps-icon-custom-size: 32px;
$steps-icon-custom-top: 0px;
$steps-icon-custom-font-size: 24px;
$steps-icon-top: -0.5px;
$steps-icon-font-size: 16px;
$steps-icon-margin: 0 8px 0 0;
$steps-title-line-height: 32px;
$steps-small-icon-size: 24px;
$steps-small-icon-margin: 0 8px 0 0;
$steps-dot-size: 8px;
$steps-dot-top: 2px;
$steps-current-dot-size: 10px;
$steps-description-max-width: 140px;
$steps-nav-content-max-width: auto;
$steps-vertical-icon-width: 16px;
$steps-vertical-tail-width: 16px;
$steps-vertical-tail-width-sm: 12px;
$notification-bg: #fff;
$notification-padding-vertical: 16px;
$notification-padding-horizontal: 24px;
$result-title-font-size: 24px;
$result-subtitle-font-size: 14px;
$result-icon-font-size: 72px;
$result-extra-margin: 24px 0 0 0;
$image-size-base: 48px;
$image-font-size-base: 24px;
$image-bg: #f5f5f5;
$image-color: #fff;
$image-mask-font-size: 16px;
$image-preview-operation-size: 18px;
$image-preview-operation-color: rgba(255, 255, 255, 0.85);
$image-preview-operation-disabled-color: rgba(255, 255, 255, 0.25);
$segmented-bg: rgba(0, 0, 0, 0.04);
$segmented-hover-bg: rgba(0, 0, 0, 0.06);
$segmented-selected-bg: #fff;
$segmented-label-color: rgba(0, 0, 0, 0.65);
$segmented-label-hover-color: #262626;
$primary-hover-color: #cfeff9;
$secondary-color: #90a7b9;
$secondary-1: #ebf6fa;
$secondary-2: #dfe9ed;
$secondary-3: #d3dce0;
$secondary-4: #c7cfd4;
$secondary-5: #bbc2c7;
$secondary-6: #90a7b9;
$secondary-7: #6c8194;
$secondary-8: #4b5c6e;
$secondary-9: #2d3947;
$secondary-10: #151a21;
$gray-base: #bfbfbf;
$gray-1: #fff;
$gray-2: #fafafa;
$gray-3: #f5f5f5;
$gray-4: #f0f0f0;
$gray-5: #d9d9d9;
$gray-6: #bfbfbf;
$gray-7: #8c8c8c;
$gray-8: #595959;
$gray-9: #434343;
$gray-10: #262626;
$gray-11: #1f1f1f;
$gray-12: #141414;
$gray-13: #000;
$error-1: #f65d70;
