body {
  margin: 0;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 19px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: transparent !important;
  opacity: 1;
  /* Firefox */
}

input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: transparent !important;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: transparent !important;
  opacity: 1;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: transparent !important;
  opacity: 1;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: transparent !important;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: transparent !important;
}

.ant-input-lg {
  font-size: 14px !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 10px 16px !important;
}

.ant-notification-notice {
  background: #fff2f0 !important;
  border: 1px solid #ffccc7 !important;
  width: fit-content !important;
  padding: 10px 43px 13px 10px !important;
  color: #ff4d4f !important;
}

.ant-notification-notice-close {
  top: 15px !important;
}

.ant-notification-notice-description {
  margin-left: 40px !important;
}

.triggerProbabilityModal {
  .probabilityTrigger {
    width: 100%;
    // height: 110px !important;
    background: #ffffff;

    .probabilityTriggerTitle {
      font-weight: 700;
    }

    .probabilityTriggerContent {
      margin-top: 16px;
      display: flex;
      position: relative;

      .probabilityValue {
        font-weight: 700;
        color: #00a8e1 !important;
      }

      .probabilityValueSection {
        display: flex;
        align-items: center;

        .probabilityValue {
          font-weight: 700;
          color: #00a8e1 !important;
          // font-size: 20px;
        }
      }

      .probabilityTriggerText {
        color: #90a7b9;
        // width: 245px;
        width: 50%;
        height: 57px;
        float: right;
        line-height: 19px;
        position: absolute;
        right: 0;
      }
    }
  }
}

.confirmationModal {
  .ant-modal-footer {
    padding: 0px 16px 16px 16px !important;
    border: none !important;
  }

  .ant-modal-body {
    padding: 14px 24px 6px 24px !important;
  }

  .ant-modal-header {
    border-bottom: none !important;
    padding: 25px 24px 10px 24px !important;
  }
}

.frequencyDistributionModal {
  // .modalHeading {
  //   display: flex;
  //   align-items: center;
  //   .modalTitle {
  //     color: #90a7b9;
  //     font-size: 24px;
  //     font-weight: 700;
  //   }
  //   .alertMessageSection {
  //     position: absolute;
  //     right: 10px;
  //   }
  // }
  .inputSection {
    width: 525px;
    margin-left: 15px;
    position: relative;

    .alertMessageSection {
      position: absolute;
      left: 16px;
    }

    .modalFooter {
      padding-top: 6px !important;
      display: flex !important;
      align-items: center !important;
      gap: 16px !important;

      .footerItem {
        width: fit-content;
        display: flex;
        align-items: center;
      }
    }

    .modalHeader {
      border-bottom: 1px solid #e0e0e0 !important;
      padding: 16px 24px !important;
      color: rgba(0, 0, 0, 0.54);
      font-size: 24px;
    }
  }

  .readonlySection {
    width: 476px;

    .greyBorder {
      border: 1.5px solid #e7e7e7;
      border-radius: 4px;
      padding: 10px;
      height: calc(100% - 50px);
    }

    .modalTitle {
      color: #90a7b9;
      font-size: 24px;
      font-weight: 700;
      // margin-top: 20px;
    }

    .modalFooter {
      position: absolute;
      bottom: 6px;
      width: 476px;
      height: 50px;
    }

    .optionSection {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
    }

    .imageSection {
      background-color: #fff;
      width: 463px;
      background: #ffffff;
      position: absolute;
      bottom: 170px;
      height: 293px !important;
    }
  }
}

.greyBackground {
  background-color: #ececec;
  padding: 10px 16px 0 16px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.fileUploadModal {
  .ant-modal-footer {
    padding: 0px 16px 16px 16px !important;
    border: none !important;
    text-align: center;
  }

  .ant-modal-body {
    padding: 16px 24px 24px 24px !important;
  }

  .file-upload {
    background-color: #ffffff;
    padding: 0px 0px 0px 0px;
    border-radius: 8px;

    .upload-text {
      color: #90a7b9;
      font-size: 16px;
      margin-bottom: 15px;
      font-weight: 500;
    }

    .file-dragger {
      height: 180px;
      width: 470px;

      .drag-text {
        font-size: 12px;
        line-height: 1.5;
        color: #8c8c8c;
        margin-top: 10px;

        // .browse-text {
        //   color: $blueberry;
        // }
      }

      .ant-upload.ant-upload-drag {
        background: #ffffff;
        border-radius: 8px;
        border: 2px dashed #d9d9d9;
      }
    }

    .uploaded-file-info {
      background-color: #ffffff;
      height: 180px;
      width: 470px;
      border-radius: 8px;
      border: 2px dashed #d9d9d9;
      display: block;
      text-align: center;

      .file-name {
        // font-size: 12px;
        line-height: 1.5;
        margin: 0 5px 0 8px;
      }

      .file-size {
        // font-size: 12px;
        line-height: 1.5;
        color: #8c8c8c;
      }

      // .remove-file {
      //   // font-size: 10px;
      //   color: #ff4d4f;
      //   margin-left: auto;
      //   margin-right: 5px;
      //   cursor: pointer;
      // }
    }

    // .file-uploading-status {
    //   @include basic-font-and-text($font-size: 10px, $color: $greyish-purple);
    //   margin-top: 7px;
    //   .success {
    //     color: $green;
    //   }
    //   .uploading {
    //     img {
    //       width: 15px;
    //     }
    //   }
    //   .error {
    //     color: $rosy-pink;
    //   }
    //   .upload-status {
    //     margin-right: 10px;
    //   }
    // }
    .errorText {
      color: #ff4d4f;
    }

    .file-upload-submit-section {
      margin-top: 20px;
    }
  }

  .flex {
    display: flex;
  }

  .hidden {
    display: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
