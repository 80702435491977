@import "styles/common";

.UserGuideContainer {
  width: 100%;
  display: block;
  .title {
    position: relative;
    margin: auto;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: $secondary-6;
    height: 27px;
    width: 641px;
    margin-top: 30px;
  }
  .userGuideTextContent {
    position: relative;
    margin: auto;
    width: 641px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(36, 36, 36, 0.10454);
    margin-top: 15px;
    padding: 1px 16px;
    .contentTitle {
      font-weight: 700;
      line-height: 19px;
      margin: 14px 0px;
    }
    .paragraph {
      line-height: 19px;
      margin: 14px 0px;
      color: $secondary-7;
      span.frac {
        display: inline-block;
        text-align: center;
        vertical-align: middle;
      }
      span.frac > sup,
      span.frac > sub {
        display: block;
        padding: 0 0.3em;
      }
      span.frac > sup {
        border-bottom: 0.08em solid;
        padding-bottom: 5px;
      }
      span.frac > span {
        display: none;
      }
      span.brac {
        font-size: 16px;
      }
      ul,
      ol {
        padding-inline-start: 0;
        margin: 0;
        li {
          margin: 8px 16px;
        }
      }
    }
  }
  .nextButtonSection {
    text-align: center;
    margin-top: calc(24px - 19px / 2);
    .nextButton {
      // padding: 9px 23px;
      width: 93px;
      height: 37px;
    }
  }
}
