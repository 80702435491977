@import "styles/common";

.section {
  height: 100%;
  overflow: auto;
  .container {
    height: 100%;
    // overflow: auto !important;
    min-height: calc(100vh - (48px + #{$footer-md-height}));
    position: relative;
    padding-top: 48px;
    padding-bottom: calc(#{$footer-md-height} + 16px);
    display: flex;
    justify-content: center;

    @include for-both-phone-and-tablet {
      min-height: calc(100vh - (#{$header-xs-height} + #{$footer-xs-height}));
      // margin-top: $header-xs-height;
    }
  }
}
