@import "styles/common";

.footerContainer {
  height: $footer-md-height;
  background-color: $white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 30px;
  @include for-phone-only {
    height: $footer-xs-height;
    padding: 15px;
  }
  .linkSection {
    display: flex;
    flex-direction: row;
    .footerLink {
      margin-right: 20px;
      font-size: 12px;
      line-height: 16px;
    }
    @include for-phone-only {
      flex-direction: column;
    }
  }
  .spacer {
    flex: 1;
  }
  .userGuide {
    font-size: 12px;
    margin-right: 20px;
    margin-bottom: 5px;
    line-height: 16px;
    padding: 0;
    font-weight: 400;
  }
  .copyrightSection {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: center;
    .copyrightText {
      margin-right: 10px;
      font-size: 12px;
      line-height: 16px;
      width: 68px;
    }
    img {
      height: 20px;
      width: auto;
    }
    @include for-phone-only {
      flex-direction: column;
    }
  }
}
