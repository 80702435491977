@import-normalize;
@import "theme";

/*
Common variables defined
*/

$header-md-height: 52px;
$header-xs-height: 41px;
$footer-md-height: 52px;
$footer-xs-height: 100px;

.ant-tooltip-inner {
  color: #000000 !important;
  background-color: #ffffff;
  width: 389px;
  height: fit-content;
  padding: 20px;
}

.ant-tooltip-arrow-content {
  background-color: #ffffff;
}

.ant-popover-title {
  border-bottom: none !important;
  padding: 9px 16px 0px;
  font-weight: 700;
}

.ant-popover-inner {
  width: 273px !important;
}

.ant-collapse {
  background-color: inherit !important;
}

.zipCodeModal {
  .zipcodeModalText {
    .paragraph {
      line-height: 19px !important;
      margin: 16px 0px !important;
      color: $secondary-6 !important;
    }
  }
}

@mixin for-phone-only {
  @media (max-width: $screen-xs-max) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    @content;
  }
}

@mixin for-both-phone-and-tablet {
  @media (max-width: $screen-md-max) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: $screen-lg-min) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: $screen-xl-min) {
    @content;
  }
}

.colorPrimary {
  color: $primary-color !important;
}

.loadingContainer {
  width: 100%;
  background-color: $white;
  padding: 15px;
}

.headerText {
  color: $primary-color;
  margin-bottom: 0;
}

h4,
h3,
h2,
h1 {
  &.headerText {
    color: $primary-color;
    margin-bottom: 0;
  }
}

.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  font-weight: 700;
  text-transform: capitalize;
}

.hiddenXS {
  @include for-both-phone-and-tablet {
    display: none;
  }
}

.hiddenMD {
  @include for-desktop-up {
    display: none;
  }
}

.ant-drawer {
  .ant-drawer-wrapper-body {
    .ant-drawer-header {
      background-color: $primary-color;
      padding: 15px;
      border-radius: 0;
      .ant-drawer-title {
        .sidebar-logo {
          img {
            width: 90px;
            height: auto;
          }
        }
      }
    }
    .ant-drawer-body {
      padding: 15px;
      display: flex;
      flex-direction: column;
      .sidebarUserContainer {
        h4 {
          margin-bottom: 0;
          color: $primary-color;
          font-size: 16px;
          line-height: 16px;
        }
        .roleText {
          font-size: 12px;
          line-height: 10px;
          text-transform: capitalize;
        }
      }
      .divider {
        margin: 10px 0;
      }
      .sidebar-routes-section {
        display: flex;
        flex-direction: column;
        height: 100%;
        .route-section {
          .route-url {
            display: flex;
            .sidebar-link {
              color: $text-color-secondary;
              text-transform: capitalize;
              font-weight: 700;
              line-height: 20px;
              width: 100%;
              &.active {
                color: $primary-color;
              }
            }
          }
        }
        .spacer {
          flex: 1;
        }
        .sidebarAccountSection {
          .sidebarAccountButton {
            color: $text-color-secondary;
            text-transform: capitalize;
            padding-left: 0;
            padding-right: 0;
            width: 100%;
            text-align: left;
          }
        }
      }
    }
  }
}

.ant-alert-error {
  padding: 5px 16px !important;
  .ant-alert-message {
    color: #ff4d4f !important;
  }
  .ant-alert-icon {
    font-size: 16px !important;
  }
}
